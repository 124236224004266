const config = {
  header: "Header",
  headerBackground: "#ff8ba7",
  bodyBackground: "#ffc6c7",
  title: "Title",
  subTitle: "Sub Title",
  airtable: "https://airtable.com/embed/shrdi24ocwoEeXx6d?backgroundColor=red&viewControls=on",
  googleAnalytics: "UA-XXXXXXXX-X"
}

export default config